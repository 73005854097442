import { render, staticRenderFns } from "./CustomerNotificationStepTwo.vue?vue&type=template&id=6d322808&"
import script from "./CustomerNotificationStepTwo.vue?vue&type=script&lang=js&"
export * from "./CustomerNotificationStepTwo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports