<template>
  <div class="customerNotificationStepTwo">
    <LoadingPlaceholder v-if="loading" />
    <div
      v-else
      v-show="showStep"
      class="mb-3"
    >
      <input
        v-model="filter"
        placeholder="search ..."
        class="mb-4 form-control"
      >
      <table class="defaultTable m-0">
        <thead>
          <!-- FOR LARGE SCREENS -->
          <tr class="d-none d-md-table-row">
            <th class="font-weight-bold">
              {{ $t('name') }}
            </th>
            <th class="font-weight-bold" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(template, index) in filterByTitle"
            :key="'tr1' + index"
          >
            <td v-if="template.name">
              {{ template.name }}
            </td>
            <td>
              <button
                class="btn btn-sm btn-primary"
                @click="nextStep(template)"
              >
                {{ $t('choose') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        class="btn btn-sm btn-primary float-left mt-3 mb-2"
        type="button"
        @click="prevStep"
      >
        <font-awesome-icon
          class="mr-1"
          icon="arrow-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerNotificationStepTwo',
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    stepOneObj: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showStep: false,
      templateList: [],
      filter: null,
      loading: true
    }
  },
  computed: {
    filterByTitle () {
      if (!this.filter) {
        return this.templateList;
      }
      return this.templateList.filter(template => template.name.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
  watch: {
    active (val) {
      this.showStep = val;
    }
  },
  created () {
    this.showStep = this.active;
    this.getAllTemplates()
  },
  methods: {
    getAllTemplates () {
      this.axios.get('/SmsManagement/GetTemplatesByInstallationTypeId?installationTypeId=-1')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.length == null) {
            return;
          }
          if (response.data.length == 0) {
            return;
          }
          this.templateList = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    nextStep (val) {
      val.installationType = 'Customer Notification'
      this.$emit("goStepThree", val)
    },
    prevStep () {
      this.$emit("goStepOne", this.stepOneObj)
    }
  }
}
</script>
